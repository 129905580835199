<template>
    <!-- Stuff left: 
        - support properties
        - contains 'a b' -> like '%a%' AND like '%b%'. Should be like '%a b%'. See comment in filterItemsBeforeSave
        - receive viewname instead of dataobject (fieldsDataObject)?

    -->

    <div v-if="filterItems.mode == 'and'">
        <table class="mb-1 w-100">
            <thead>
                <tr style="background-color:lightsteelblue;">
                    <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:6%;"></th>
                    <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:27%">{{ $t("Fieldname") }}</th>
                    <th style="border: solid 1px silver;font-size:small;width:25%">{{ $t("Criteria") }}</th>
                    <th style="border: solid 1px silver;font-size:small;width:35%">{{ $t("Value") }}</th>
                    <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:5%;"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(andItem,index2) in filterItems.items">
                    <td class="text-center">
                        <span v-if="index2 > 0">{{$t('And')}}</span>
                    </td>
                    <td>
                        <select v-if="props.fieldsDataObject.fields.fields.filter(obj=>obj.field === andItem.column).length" v-model="andItem.column" class="form-control form-control-sm" @change="setTypeAndOperator(andItem,$event.target.value)">
                            <option :value="op.field" v-for="op in props.fieldsDataObject.fields.fields.sort((a,b)=>a.caption>b.caption ? 1 : -1)">
                                {{ $t(op.caption) }}
                            </option>
                        </select>
                        <ODataLookup v-else-if="props.propertiesDataObject" :bind="sel => handlePropertySelection(andItem, sel)"
                            :data-object="props.propertiesDataObject">
                            <OColumn name="Name"></OColumn>
                            <template #target="{ target }">
                                <input :ref="target" :value="andItem.column.replace('prop_', '') + ' (' + $t('property') + ')'" class="form-control form-control-sm">
                            </template> 
                        </ODataLookup>
                        <!-- <div v-if="props.propertiesDataObject"> -->
                            <!-- / -->
                            <!-- <ODataLookup :bind="sel => handlePropertySelection(sel, andItem)" -->
                                <!-- :data-object="props.propertiesDataObject"> -->
                                <!-- <OColumn name="Caption"></OColumn> -->
                                <!-- <template #target="{ target }"> -->
                                    <!-- <input :ref="target" :placeholder="$t('Select property')" class="form-control form-control-sm"> -->
                                <!-- </template> --> 
                            <!-- </ODataLookup> -->
                
                        <!-- </div> -->
                        
                    </td>
                    <td>
                        <select v-model="andItem.operator" class="form-control form-control-sm">
                            <option :value="op.name" v-for="op in getOperatorsList2(andItem.valueType,andItem.column)">
                                {{ $t(op.title) }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <div class="input-group">
                            <input v-model="andItem.value" type="text" :disabled="getType(andItem.valueType,andItem.column) == 'bit' || ['isnotblank','isblank','isnull','isnotnull'].indexOf(andItem.operator) > -1" class="form-control form-control-sm">
                            <ODataLookup v-if="props.lookups[andItem.column] && !(getType(andItem.valueType,andItem.column) == 'bit' || ['isnotblank','isblank','isnull','isnotnull'].indexOf(andItem.operator) > -1)" :bind="sel => handleLookupSelection(sel, andItem)"
                                :data-object="props.lookups[andItem.column].DataObject">
                                <OColumn :name="props.lookups[andItem.column].Column"></OColumn>
                                <template #target="{ target }">
                                    <button :ref="target" class="btn btn-link btn-sm">
                                        <i class="bi bi-chevron-down"></i>
                                    </button>
                                </template> 
                            </ODataLookup>
                        </div>
                    </td>
                    <td class="text-center">
                        <i class="bi bi-x" style="cursor:pointer;" @click="deleteItem(null,index2)"></i>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">
                        <span v-if="filterItems.items.length">{{$t('And')}}</span>
                    </td>
                    <td>
                        <div class="input-group input-group-sm">
                            <select class="form-control form-control-sm" @change="setTypeAndOperator(null,$event.target.value);$event.target.value = ''">
                                <option disabled value="" selected>{{$t('Select field')}}</option>
                                <option :value="op.field" v-for="op in props.fieldsDataObject.fields.fields.sort((a,b)=>a.caption>b.caption ? 1 : -1)">
                                    {{ $t(op.caption) }}
                                </option>
                            </select>
                            <small v-if="props.propertiesDataObject" class="input-group-text">/</small>
                            <ODataLookup v-if="props.propertiesDataObject" :bind="sel => handlePropertySelection(null, sel)"
                                :data-object="props.propertiesDataObject">
                                <OColumn name="Name"></OColumn>
                                <template #target="{ target }">
                                    <input :ref="target" :placeholder="$t('Property')" class="form-control form-control-sm">
                                </template> 
                            </ODataLookup>
                    
                        </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <div v-if="filterItems.items.length">
            <button class="btn btn-outline-primary btn-sm" @click="newOr = true" :disabled="newOr">{{ $t('Or (add group)') }}</button>
        </div>

    </div>

    <div v-for="(orGroup,index) in filterItems.items" v-else-if="filterItems.mode == 'or'">
        <div v-if="index > 0">{{$t('Or')}} <i class="bi bi-x" style="cursor:pointer;" @click="deleteGroup(index)"></i></div>
        <table class="mb-3 w-100">
            <thead v-if="index == 0">
                <tr style="background-color:lightsteelblue;">
                    <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:6%;"></th>
                    <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:27%">{{ $t("Fieldname") }}</th>
                    <th style="border: solid 1px silver;font-size:small;width:25%">{{ $t("Criteria") }}</th>
                    <th style="border: solid 1px silver;font-size:small;width:35%">{{ $t("Value") }}</th>
                    <th style="padding-right:15px;border: solid 1px silver;font-size:small;width:5%;"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(andItem,index2) in orGroup.items">
                    <td class="text-center" style="width:6%;">
                        <span v-if="index2 > 0">{{$t('And')}}</span>
                    </td>
                    <td style="width:27%;">
                        <select v-if="props.fieldsDataObject.fields.fields.filter(obj=>obj.field === andItem.column).length" v-model="andItem.column" class="form-control form-control-sm" @change="setTypeAndOperator(andItem,$event.target.value)">
                            <option :value="op.field" v-for="op in props.fieldsDataObject.fields.fields.sort((a,b)=>a.caption>b.caption ? 1 : -1)">
                                {{ $t(op.caption) }}
                            </option>
                        </select>
                        <ODataLookup v-else-if="props.propertiesDataObject" :bind="sel => handlePropertySelection(andItem, sel)"
                            :data-object="props.propertiesDataObject">
                            <OColumn name="Name"></OColumn>
                            <template #target="{ target }">
                                <input :ref="target" :value="andItem.column.replace('prop_', '') + ' (' + $t('property') + ')'" class="form-control form-control-sm">
                            </template> 
                        </ODataLookup>
                    </td>
                    <td style="width:25%;">
                        <select v-model="andItem.operator" class="form-control form-control-sm">
                            <option :value="op.name" v-for="op in getOperatorsList2(andItem.valueType,andItem.column)">
                                {{ $t(op.title) }}
                            </option>
                        </select>
                    </td>
                    <td style="width:35%;">
                        <div class="input-group">
                            <input v-model="andItem.value" type="text" :disabled="getType(andItem.valueType,andItem.column) == 'bit' || ['isnotblank','isblank','isnull','isnotnull'].indexOf(andItem.operator) > -1" class="form-control form-control-sm">
                            <ODataLookup v-if="props.lookups[andItem.column] && !(getType(andItem.valueType,andItem.column) == 'bit' || ['isnotblank','isblank','isnull','isnotnull'].indexOf(andItem.operator) > -1)" :bind="sel => handleLookupSelection(sel, andItem)"
                                :data-object="props.lookups[andItem.column].DataObject">
                                <OColumn :name="props.lookups[andItem.column].Column"></OColumn>
                                <template #target="{ target }">
                                    <button :ref="target" class="btn btn-link btn-sm">
                                        <i class="bi bi-chevron-down"></i>
                                    </button>
                                </template> 
                            </ODataLookup>
                        </div>
                    </td>
                    <td class="text-center" style="width:5%;">
                        <i class="bi bi-x" style="cursor:pointer;" @click="deleteItem(index,index2)"></i>
                    </td>
                </tr>
                <tr>
                    <td class="text-center" style="width:6%;">
                        <span v-if="orGroup.items.length">{{$t('And')}}</span>
                    </td>
                    <td style="width:27%;">
                        <!-- <select class="form-control form-control-sm" @change="setTypeAndOperator(null,$event.target.value, index);$event.target.value = ''"> -->
                            <!-- <option disabled value="" selected>{{$t('Select field')}}</option> -->
                            <!-- <option :value="op.field" v-for="op in props.fieldsDataObject.fields.fields.sort((a,b)=>a.caption>b.caption ? 1 : -1)"> -->
                                <!-- {{ $t(op.caption) }} -->
                            <!-- </option> -->
                        <!-- </select> -->
                        <div class="input-group input-group-sm">
                            <select class="form-control form-control-sm" @change="setTypeAndOperator(null,$event.target.value, index);$event.target.value = ''">
                                <option disabled value="" selected>{{$t('Select field')}}</option>
                                <option :value="op.field" v-for="op in props.fieldsDataObject.fields.fields.sort((a,b)=>a.caption>b.caption ? 1 : -1)">
                                    {{ $t(op.caption) }}
                                </option>
                            </select>
                            <small v-if="props.propertiesDataObject" class="input-group-text">/</small>
                            <ODataLookup v-if="props.propertiesDataObject" :bind="sel => handlePropertySelection(null, sel, index)"
                                :data-object="props.propertiesDataObject">
                                <OColumn name="Name"></OColumn>
                                <template #target="{ target }">
                                    <input :ref="target" :placeholder="$t('Property')" class="form-control form-control-sm">
                                </template> 
                            </ODataLookup>
                    
                        </div>
                    </td>
                    <td style="width:25%;"></td>
                    <td style="width:35%;"></td>
                    <td style="width:5%;"></td>
                </tr>
            </tbody>
        </table>
        <div v-if="index == filterItems.items.length-1 && filterItems.items[index].items.length">
        <button class="btn btn-outline-primary btn-sm" @click="newOr = true" :disabled="newOr">{{ $t('Or (add group)') }}</button>
        </div>
    </div>

    <div v-if="newOr">
        <div>{{$t('Or')}} <i class="bi bi-x" style="cursor:pointer;" @click="newOr = false"></i></div>
        <table class="mb-3 w-100">

            <tbody>
                <tr>
                    <td class="text-center" style="width:6%;">
                    </td>
                    <td style="width:27%;">
                        <!-- <select class="form-control form-control-sm" @change="setTypeAndOperator(null,$event.target.value, null, true);$event.target.value = ''"> -->
                            <!-- <option disabled value="" selected>{{$t('Select field')}}</option> -->
                            <!-- <option :value="op.field" v-for="op in props.fieldsDataObject.fields.fields.sort((a,b)=>a.caption>b.caption ? 1 : -1)"> -->
                                <!-- {{ $t(op.caption) }} -->
                            <!-- </option> -->
                        <!-- </select> -->
                        <div class="input-group input-group-sm">
                            <select class="form-control form-control-sm" @change="setTypeAndOperator(null,$event.target.value, null, true);$event.target.value = ''">
                                <option disabled value="" selected>{{$t('Select field')}}</option>
                                <option :value="op.field" v-for="op in props.fieldsDataObject.fields.fields.sort((a,b)=>a.caption>b.caption ? 1 : -1)">
                                    {{ $t(op.caption) }}
                                </option>
                            </select>
                            <small v-if="props.propertiesDataObject" class="input-group-text">/</small>
                            <ODataLookup v-if="props.propertiesDataObject" :bind="sel => handlePropertySelection(null, sel, null, true)"
                                :data-object="props.propertiesDataObject">
                                <OColumn name="Name"></OColumn>
                                <template #target="{ target }">
                                    <input :ref="target" :placeholder="$t('Property')" class="form-control form-control-sm">
                                </template> 
                            </ODataLookup>
                    
                        </div>
                    </td>
                    <td style="width:25%;"></td>
                    <td style="width:35%;"></td>
                    <td style="width:5%;" class="text-center">
                        <!-- <i class="bi bi-x" style="cursor:pointer;" @click="newOr = false"></i> -->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>


    <div v-if="!filterItems.mode == 'and' && !filterItems.mode == 'or'">
        {{$t('Could not set up filter builder. Edit textually:')}}
        <OTextArea v-model="filterStringTextEdit" class="form-control from-control-sm" autocomplete="false" spellcheck="false" autoGrow noResize/>
    </div>
</template>

<script setup>
import { ref, computed, onMounted,watch } from 'vue';
import { getOrCreateProcedure } from 'o365-modules';
import { filterUtils, Devex} from 'o365-filterobject';

const { createInitialObject, filterItemsToString, prettifyFilterItems, filterStringToFilterItems, getOperatorTitle, getOperatorsList, getDefaultOperator } = filterUtils;

const props = defineProps({
    filterString: String,
    fieldsDataObject: Object,
    propertiesDataObject: Object,
    //propertiesViewName: String,
    lookups: Object

})

if (props.propertiesDataObject){
    let currentClause = props.propertiesDataObject.recordSource.whereClause;
    props.propertiesDataObject.recordSource.whereClause = (currentClause ? currentClause + ' AND ' : '') + "DataType = 'string'";
    // props.propertiesDataObject.recordSource.filterString = "DataType = 'string'";
}
console.log(props.lookups)

const filterItems = ref({type: "group", mode:"and",items:[]}); // createInitialObject instead?
const filterStringTextEdit = ref("");
const newOr = ref(false);

const loadBuilder = async ()=>{
    if (props.filterString){
        var vData = await filterStringToFilterItems(props.filterString);
        filterItemsAfterLoad(vData);
        filterItems.value = vData;
    } else {
        filterItems.value = {type: "group", mode:"and",items:[]};
    }
    filterStringTextEdit.value = props.filterString;
}

watch(()=>props.filterString, loadBuilder, { immediate: true });


const getOperatorsList2 = (type,col)=>{
    //var vType = props.fieldsDataObject.fields[col].type;
    let vType = props.fieldsDataObject.fields[col] ? props.fieldsDataObject.fields[col].type : type ?? "string";
    return getOperatorsList(vType)
}

const getType = (type, col)=>{
    return props.fieldsDataObject.fields[col] ? props.fieldsDataObject.fields[col].type : type ?? "string"
}

const setTypeAndOperator = async (item, newField, orGroupIndex, isNewOr) => {
    console.log(props.lookups[newField])
    if (item){
        if (item.valueType != props.fieldsDataObject.fields[newField].type){
            item.valueType = props.fieldsDataObject.fields[newField].type;
            item.operator = getDefaultOperator(item.valueType);
            item.value = null;
        }
    } else if (!isNewOr){
        var newItemType = props.fieldsDataObject.fields[newField].type;
        console.log(newItemType)

        if (orGroupIndex || orGroupIndex === 0){
            filterItems.value.items[orGroupIndex].items.push({type: "expression", column:newField,valueType:newItemType,operator:getDefaultOperator(newItemType), value: ""});
        } else {
            filterItems.value.items.push({type: "expression", column:newField,valueType:newItemType,operator:getDefaultOperator(newItemType), value: ""});
        }
    } else {
        var newItemType = newField ? props.fieldsDataObject.fields[newField].type : null;
        var newOperator = newItemType ? getDefaultOperator(newItemType) : null;
        if (filterItems.value.mode == 'or'){//if (orGroupIndex || orGroupIndex === 0){
            filterItems.value.items.push({type: "group", mode:"and", items:[{type: "expression", column:newField,valueType:newItemType,operator:newOperator, value: ""}]});

        } else {
            var curCriteria = filterItemsToString(filterItems.value);
            var newCriteria = filterItemsToString({type: "expression", column:newField,valueType:newItemType,operator:newOperator, value: ""});

            var vData = await filterStringToFilterItems("("+curCriteria +") OR " + newCriteria);

            filterItemsAfterLoad(vData);
            filterItems.value = vData;
        }
        newOr.value = false;
    }
}

const handlePropertySelection = async (item, sel, orGroupIndex, isNewOr) => {
    //console.log(props.lookups[newField])
    if (item){
        item.column = "prop_"+sel.Name;
        if (item.valueType != sel.DataType){
            item.valueType =sel.DataType;
            item.operator = getDefaultOperator(sel.DataType);
            item.value = null;
        }
    } else if (!isNewOr){
        // var newItemType = props.fieldsDataObject.fields[newField].type;
        // console.log(newItemType)

        if (orGroupIndex || orGroupIndex === 0){
            filterItems.value.items[orGroupIndex].items.push({type: "expression", column:"prop_"+sel.Name,valueType:sel.DataType,operator:getDefaultOperator(sel.DataType), value: ""});
        } else {
            filterItems.value.items.push({type: "expression", column:"prop_"+sel.Name,valueType:sel.DataType,operator:getDefaultOperator(sel.DataType), value: ""});
        }
    } else {
        //var newItemType = newField ? props.fieldsDataObject.fields[newField].type : null;
        var newOperator = sel.DataType ? getDefaultOperator(sel.DataType) : null;
        if (filterItems.value.mode == 'or'){//if (orGroupIndex || orGroupIndex === 0){
            filterItems.value.items.push({type: "group", mode:"and", items:[{type: "expression", column:"prop_"+sel.Name,valueType:sel.DataType,operator:newOperator, value: ""}]});

        } else {
            var curCriteria = filterItemsToString(filterItems.value);
            var newCriteria = filterItemsToString({type: "expression", column:"prop_"+sel.Name,valueType:sel.DataType,operator:newOperator, value: ""});

            var vData = await filterStringToFilterItems("("+curCriteria +") OR " + newCriteria);

            filterItemsAfterLoad(vData);
            filterItems.value = vData;
        }
        newOr.value = false;
    }
}

const deleteItem = (index,index2) => {
    if (index || index === 0){
        filterItems.value.items[index].items.splice(index2,1);
    } else {
        filterItems.value.items.splice(index2,1);
    }
    
}
const deleteGroup = (index) => {
    filterItems.value.items.splice(index,1);
}

const getFilterString = () => {
    if (filterItems.value.mode == 'and' || filterItems.value.mode == 'or'){
        filterItemsBeforeSave(filterItems.value);
        var vFilter = filterItemsToString(filterItems.value);
        return vFilter
    } else {
        return filterStringTextEdit.value
    }
    
}
const filterItemsAfterLoad = (pFilterItems) => {
    if (pFilterItems.items){
        console.log(pFilterItems.items);
        //debugger;
        // let isSupportedFilter = true;
        pFilterItems.items.forEach((data) => {
            if (data.type === "expression"){
                if (data.column){
                    if (props.fieldsDataObject.fields[data.column] && props.fieldsDataObject.fields[data.column].type === "bit"){
                        data.operator = data.value ? "istrue" : "isfalse";
                    } else if (!data.value && data.operator == "notequals"){
                        data.operator = "isnotblank"
                    }
                    
                }
            }else if (data.items) {
                data.items.forEach((data2) => {
                    if (data2.column){
                        if (props.fieldsDataObject.fields[data2.column] && props.fieldsDataObject.fields[data2.column].type === "bit"){
                            data2.operator = data2.value ? "istrue" : "isfalse";
                        } else if (!data2.value && data2.operator == "notequals"){
                            data2.operator = "isnotblank"
                        }
                        
                    }
                });
            }

        });
        // if (!isSupportedFilter){
            // filterItems = ref({type: "group", mode:"and",items:[]}); // createInitialObject instead?
        // }
    }
}

const filterItemsBeforeSave = (pFilterItems) => {
    // as of now, contains-items must be workarounded if it has spaces to prevent "and" x #spaces
    if (pFilterItems.items){
        let curItem, curItem2;
        for (let i=pFilterItems.items.length-1;i>=0;i--){
            curItem = pFilterItems.items[i];
            if (curItem.type === "expression"){
                if (!curItem.value && ["istrue", "isfalse","isblank", "isnotblank", "isnull", "isnotnull"].indexOf(curItem.operator) == -1){
                    pFilterItems.items.splice(i, 1);
                } else if (["inlist", "notinlist"].indexOf(curItem.operator) > -1 && curItem.value && curItem.value.constructor !== Array){
                    curItem.value = curItem.value.split(',').map(str => str.trim());
                } else if (["inlist", "notinlist"].indexOf(curItem.operator) == -1 && curItem.value && curItem.value.constructor === Array){
                    curItem.value = curItem.value.join();
                }
            } else if (curItem.items){
                for (let j=curItem.items.length-1;j>=0;j--){
                    curItem2 = curItem.items[j];
                    if (!curItem2.value && ["istrue", "isfalse","isblank", "isnotblank", "isnull", "isnotnull"].indexOf(curItem2.operator) == -1){
                        curItem.items.splice(j, 1);
                    } else if (["inlist", "notinlist"].indexOf(curItem2.operator) > -1 && curItem2.value && curItem2.value.constructor !== Array){
                        curItem2.value = curItem2.value.split(',').map(str => str.trim());
                    } else if (["inlist", "notinlist"].indexOf(curItem2.operator) == -1 && curItem2.value && curItem2.value.constructor === Array){
                        curItem2.value = curItem2.value.join();
                    }
                }
            }
        }
    }
}

const handleLookupSelection = (sel, item) => {
    let val = sel[props.lookups[item.column].Column];
    if (['notinlist','inlist'].indexOf(item.operator) > -1){
        item.value = item.value ? item.value + "," + val : val;
    } else {
        item.value = val;
    }
}

defineExpose({
    getFilterString, loadBuilder
})
</script>